<template>
  <div class="equiry">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "EnquiryCabinet"
}
</script>

<style lang="scss" scoped>
.router-link-active {
  background: #E9F4FF;
}

.equiry {
  overflow: hidden;
  background-color: #F6F6F6 ;
}
</style>